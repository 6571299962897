import request from '@/utils/request';

const PATH = '/service-key';

export const getServiceKey = () => request({
  url: PATH,
  method: 'get',
});

export const addServiceKey = () => request({
  url: PATH,
  method: 'post',
});
